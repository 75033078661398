/* 
 * eweblab.net @ ALL RIGHTS RESERVED
 * This is licensed under Creative Common CC 3.0 Non-Commerial-no-derivative
 */

import React from 'react';
import classNames from 'classnames';
import * as clipboard from "clipboard-polyfill";
import { withAlert } from 'react-alert';
const symbols = [
	'&#10004;',
	'&#10697;'
];
class CopyableInputPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {copied: false};
		this.myRef = React.createRef();
	}
	
    render() {
        return (
			<div className={classNames('panel', 'panel-default')}>
				<div className="panel-heading">
				{this.props.title}
					<span className="copyable" onClick={(e) => this.shouldDoCopy(e)}>{this.getSymbol()}</span>
				</div>
				<div className="panel-body">
					<div className="form-group">
						<div className="input text">
							<input ref={this.myRef} id={this.props.inputid} className="form-control" type="text" name={this.props.inputname} readOnly onClick={e => this.myRef.current.select()} defaultValue={this.props.inputvalue}
							data-clipboard-text={this.props.inputvalue}/>
						</div>
					</div>
				</div>
			</div>
        );
    }
	
	componentDidMount() {

	}
	
	getSymbol() {
		var parser = new DOMParser;
		var dom = parser.parseFromString('<!DOCTYPE html><body>' + (this.state.copied ? symbols[0] : symbols[1]) + '</body></html>', 'text/html');
		
		return dom.body.textContent;
	}
	
	shouldDoCopy(e) {
		this.clipboardOnCopy(e, () => {
			this.props.alert.show(this.props.copied_msg);
			this.setState({copied: true});
			setTimeout(() => this.setState({copied: false}), 2000);
		});
		//this.myRef.current.select();
	}
	
	clipboardOnCopy(e, c) {
        var dt = new clipboard.DT();
        dt.setData("text/plain", this.myRef.current.getAttribute('data-clipboard-text'));
        clipboard.write(dt);
        c && c();
    }

};

export default withAlert(CopyableInputPanel);