/* 
 * eweblab.net @ ALL RIGHTS RESERVED
 * This is licensed under Creative Common CC 3.0 Non-Commerial-no-derivative
 */

import React from 'react';
import classNames from 'classnames';
import $ from 'jquery';
var FuckAdBlock = require('fuckadblock');
const options = {
    position: 'bottom center',
    timeout: 5000,
    offset: '30px',
    transition: 'scale'
};
class DownloadButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {adblock: false};
	}
	
    render() {
        return (
			<button className={classNames('btn', 'btn-primary')} type="submit" onClick={(e) => this.checkAdBlock(e)}>
				{this.props.download_text}
			</button>
        );
    }
	
	componentDidMount() {
		window.fuckAdBlock.onDetected(() => this.adBlockDetected);
		window.fuckAdBlock.onNotDetected(() => this.adBlockNotDetected);
		var ins = $('ins.clickforceads:eq(0)');
		
		if(ins.length > 0 && ins.html().length == 0) { // || $('ins.clickforceads:eq(0)').width() == 0) {
			this.adBlockDetected();
		}
	}
	
	adBlockDetected() {
		if(!this.adblock) this.setState({adblock: true});
	}
	
	adBlockNotDetected() {
		if(this.adblock) this.setState({adblock: false});
	}
    
    l(lang_name) {
        return (typeof(window.i18n) != 'undefined' && !!window.i18n[lang_name]) ? window.i18n[lang_name] : lang_name;
    }
	
	checkAdBlock(e) {
		if(typeof window.fuckAdBlock === 'undefined' || !typeof window.fuckAdBlock.onDetected === 'function' || this.state.adblock) {
			e.preventDefault();
			
			alert(this.l('Please disable adblock'));
			return false;
		}
	}
};

export default DownloadButton;