import 'promise-polyfill/src/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
var React = require('react');
var ReactDOM = require('react-dom');
import FuckAdBlock from 'fuckadblock'
import DownloadButton from './Files/DownloadButton.jsx';
import CopyableInputPanel from './Files/CopyableInputPanel.jsx';
import CopyableInputForInlineLbl from './Files/CopyableInputForInlineLbl.jsx';
import { Provider as AlertProvider } from 'react-alert'
import $ from 'jquery';
import AlertTemplate from 'react-alert-template-basic'
const options = {
    position: 'bottom center',
    timeout: 5000,
    offset: '30px',
    transition: 'scale'
};

function attributesToAttrs(attributes) {
	var attrs = {};
	for(var i = 0; i < attributes.length; i++) {
		attrs[attributes[i].name] = attributes[i].value;
	}
	return attrs;
}

window.parseCopyableInputPanel = (panel) => {
	if(panel) {
		var attrs = attributesToAttrs(panel.attributes);
		ReactDOM.render(
		<AlertProvider template={AlertTemplate} {...options}>
			<CopyableInputPanel {...attrs}/>
		</AlertProvider>, panel);
	}
};
window.parseCopyableInputForInlineLbl = (panel) => {
	if(panel) {
		var attrs = attributesToAttrs(panel.attributes);
		ReactDOM.render(
		<AlertProvider template={AlertTemplate} {...options}>
			<CopyableInputForInlineLbl {...attrs}/>
		</AlertProvider>, panel);
	}
};
const copyableInputPanels = document.querySelectorAll('.copyable-panel');
copyableInputPanels.forEach(function(elem) {
	var attrs = attributesToAttrs(elem.attributes);
	ReactDOM.render(
	<AlertProvider template={AlertTemplate} {...options}>
		<CopyableInputPanel {...attrs}/>
	</AlertProvider>, elem);
});

var genLegacyBtn = false;
const elem = document.getElementById('downloadButton');
if(elem) {
	const attrs = attributesToAttrs(elem.attributes);
	try {
		ReactDOM.render(<DownloadButton {...attrs}/>, elem);
	} catch {
		// reactjs failed
		genLegacyBtn = true;
	}
}

if(elem.innerHTML == '' || genLegacyBtn) {
	// for reactjs failed, such as opera mini, ios webview
	var x2345dafdb = false;
	const adBlockDetected = () => {
		x2345dafdb = true;
	};
	const adBlockNotDetected = () => {
		x2345dafdb = false;
	};

	window.fuckAdBlock.onDetected(() => adBlockDetected);
	window.fuckAdBlock.onNotDetected(() => adBlockNotDetected);

	const checkAdBlock = ((e) => {
		var ins = $('ins.clickforceads:eq(0)');
		
		if(ins.length > 0 && ins.html().length == 0) { // || $('ins.clickforceads:eq(0)').width() == 0) {
			adBlockDetected();
			return false;
		}
	});

	const elem2 = document.getElementById('downloadButtonLegacy');

	elem2.style.display = 'block';
	$('<button/>', {
		class: 'btn btn-primary',
		type: 'button',
		click: ((e) => {
			checkAdBlock(e);
			if(typeof window.fuckAdBlock === 'undefined' || !typeof window.fuckAdBlock.onDetected === 'function' || x2345dafdb) {
				alert('Please disable adblock!');
			} else {
				document.querySelector('.download-form').submit();
			}
		}),
		text: elem2.getAttribute('download_text')
	}).appendTo($(elem2));
}

window.$ = $;